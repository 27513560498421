
















































































































































































































































































































































































































































 

    import { Component, Vue } from 'vue-property-decorator';
    

    import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')
    const usuario = namespace('usuario')

    import { moduloGeralService } from '@/service'; 



    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        public valordoacao = null; 

        public ilimitado = { 
            "id":1,
            "nome":"Assinatura ilimitada",
            "descricao":"Acesso ilimitado a todos os cursos disponíveis e todas funcionalidade do aplicativo", 
            "valor":'<p style="text-align: center;"><span style="font-family: georgia, palatino, serif; font-size: 13pt;">1 m&ecirc;s por <strong>35 reais</strong></span></p>\n<p style="text-align: center;"><span style="font-family: georgia, palatino, serif; font-size: 13pt;">2 m&ecirc;s por <strong>65 reais</strong></span></p>\n<p style="text-align: center;"><span style="font-family: georgia, palatino, serif; font-size: 13pt;">3 meses por <strong>95 reais</strong></span></p>\n<p style="text-align: center;"><span style="font-family: georgia, palatino, serif; font-size: 13pt;">6 meses por <strong>150 reais</strong></span></p>\n<p style="text-align: center;"><span style="font-family: georgia, palatino, serif; font-size: 13pt;">12 meses por <strong>250 reais</strong></span></p>', 
            "imagem":"1111.png"
        };
 

        @usuario.State
        public status!

        @ambiente.State
		public modulo!

        @ambiente.Action
        public buscarModulo!: (  ) => void
        
        public urlAppMobile = 'https://app.guiaonlinecursos.com.br/?token=';

        @ambiente.Action
        public alterarModuloCompra!: (modulo) => void;

        private urlApp = 'https://play.google.com/store/apps/details?id=guiaonlinechs.com.br';
        private urlAppIos = 'https://apps.apple.com/br/app/guia-online-cursos/id1610641675?platform=iphone';

        // private urlPicPay = 'https://picpay.me/elisandra.nascimento.manzoli';
        private urlPicPay = 'https://picpay.me/bruno.manzoli.nascimento/';


        private urlInstagram = 'https://www.instagram.com/guiaonlinecursos/';

        public modulos = null;

        created() { 
            if(!this.modulo && this.loggingIn){
                this.buscarModulo();
			}   

            const token = localStorage.getItem('user');

            if (token) {
                this.urlAppMobile += JSON.parse( token ); 
            } 
            window.alertProcessando(); 
            moduloGeralService.getAllDisponivel( )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
 

        }




        adquirir(modulo){
            if(!this.loggingIn){
                window.toastErro('Faça seu Login primeiro!!', '');
            }
            else{ 
                this.alterarModuloCompra(modulo);
                this.$router.push('/v2/geral/pagamento'); 
            }
        }




        atualizarModulos(){
            window.alertProcessando(); 
            
            moduloGeralService.getAllDisponivel( true )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
        }

        get loggingIn(): boolean {
            if (this.status) {
                return  this.status.loggedIn ;
            }
            return false;
        }

 


    }
